.quienes-somos{
    min-height: 100vh;
    background-color: $colorPrimary;
    display: inline-flex;

    p{
        font-family: Arial;
        margin-bottom: 1.5rem;
        letter-spacing: 1px;
        line-height: 1.2;
        &.no-margin-bot{
            margin-bottom: 0;
        }
    }
    &__section{
        padding-top: 11rem;
        width: calc(45%);
        
        &-left{
            margin-left: 5%;
            p{
                line-height: 1.3;
            }
        }

        &-right{
            margin-left: 5%;
            margin-right: 5%;
        }
    }

    &__title{
        text-shadow: 0px 3px 2px rgb(0 0 0 / 50%);
        color: white;
        font-weight: normal;
        font-size: 3rem;
        margin-bottom: 1.5rem;
        margin-top: -1rem;
        
    }
    &--flor{
        position: fixed;
        bottom: 0;
        right: 0;
        width: 33%;
    }
    &--pueblo{
        width: 100%;
    }
}



@media only screen and (max-width: 900px) {
    .quienes-somos{

        display: block;

        p{
            text-align: justify;
            &.no-margin-bot{
                padding-bottom: 1rem;
            }
        }

        &__section{

            width: calc(100% - 1rem) ;
            padding: 0 0.5rem;

            padding-top: 10rem;
        
            &-left{
                margin-left: 0;
                p{
                    line-height: 1.3;
                }
            }

            &-right{
                margin-left: 0;
                margin-right: 0;
                padding-top: 0;

            }
            
        }

        &__title{
            
            font-size: 2.5rem;
            
        }
        
        &--flor{
            width: 50%;
        }
    }
}


@media only screen and (max-width: 485px) {
    .quienes-somos{

        display: block;

        p{
            text-align: justify;
            &.no-margin-bot{
                padding-bottom: 1rem;
            }
        }

        &__section{

            width: calc(100% - 1rem) ;
            padding: 0 0.5rem;

            padding-top: 10rem;
        
            &-left{
                margin-left: 0;
                p{
                    line-height: 1.3;
                }
            }

            &-right{
                margin-left: 0;
                margin-right: 0;
                padding-top: 0;

            }
            
        }

        &__title{
            
            font-size: 2.5rem;
            
        }

        &--flor{
            width: 50%;
        }
    }
}