/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'SignPainter';
    src: url(assets/font/SignPainter.ttf) format("truetype");
 }

$colorPrimary : #b9c7ad;
$colorText : #54524f;

*{
    padding: 0;
    margin: 0;
}

button{
    &:focus{
        outline: none;
    }
}

body{
    font-family: Arial;
}

.text-light{
    font-size: 1rem;
}

.container-shadow{
    display: flex;
    justify-content: center;
    width: 100%;

    & .shadow-text {
        position: relative;
        z-index: -1;
    }

    & .shadow {
        background: $colorPrimary;
        height: 10px;
        border-radius: 10px;
        margin-top: -10px;
        width: 80%;
        left: 10%;
        position: relative;
        z-index: -2;

        &--full{
        background: $colorPrimary;
        height: 10px;
        border-radius: 10px;
        margin-top: -12px;
        width: 100%;
        position: relative;
        z-index: -2;
        }
    }
}



.header{
    position: fixed;
    width: 100%;
    height: 130px;
    background-color: white;
    // background-image: url(./assets/img/cabecera.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    z-index: 3;
}

.body{
    padding: 0 2rem;
    font-family: 'SignPainter';
}

@media only screen and (max-width: 1250px) {
    .body{
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 485px) {
    .body{
        padding: 0 0.5rem;
    }
}

@import "styles/menu"; 
@import "styles/home"; 
@import "styles/galeria"; 
@import "styles/quienes-somos"; 
@import "styles/contacto"; 